import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export default class AboutStore {
	public aboutData: any;
	constructor() {
		makeAutoObservable(this);
	}
	setAboutData = (data?: any) => {
		this.aboutData = data;
	};

	public getAboutData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.ABOUT)

			.then(({ data }) => {
			
				this.setAboutData(data?.data);
				return data;
			})
			.catch(({ data }) => {
				
				return Promise.reject(data);
			});
	};
}
