import React from "react";
//import HeaderJson from "../header.json";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
// import { Button } from "antd";

const HeaderMenuComponent: React.FC<any> = observer(() => {
	//const HeaderData = HeaderJson?.header;
	// const { menuList } = props;

	return (
		<>
			<div className="menuContainer" >
				<ul className="menuWrapper" style={{ marginRight: "20px" }}>
					<li>
						<Link to="/">Home</Link>
					</li>
						<li>
						<Link to="/network">Network</Link>
					</li>
					{/* {menuList?.map((data: any, index: any) => {
						return (
							<>
								<li key={index}>
									<Link to={data.url}>{data.title}</Link>
								</li>
							</>
						);
					})} */}
				</ul>
				{/* <div className="headerBtn">
					<Button type="primary">Client login</Button>
				</div> */}
			</div>
			
		</>
	);
});

export default HeaderMenuComponent;
