import React from "react";
// import HeaderJson from "../header.json";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
// import { ReactComponent as FaceBookIocn } from "../../../assets/images/icons/facebook.svg";
// import { ReactComponent as InstagramIocn } from "../../../assets/images/icons/instagram.svg";
// import { ReactComponent as TwitterIocn } from "../../../assets/images/icons/twitter.svg";
// import { ReactComponent as IndeedIocn } from "../../../assets/images/icons/indeed.svg";
import { Drawer } from "antd";
import SocialIcons from "./SocialIcons";
import useStore from "../../../store";

interface AddProps {
	closeDrawer: () => void;
	open?: boolean;
}

const responsiveMenu: React.FC<AddProps> = observer(({ closeDrawer, open }) => {
	// const HeaderData = HeaderJson?.header

	const {
		AUTH: { headerData },
	} = useStore();
	const socialList = headerData?.social_section;
	return (
		<>
			<Drawer placement="right" onClose={closeDrawer} open={open}>
				<div className="responsiveMenu">
					<ul className="menuWrapper">
						<li onClick={closeDrawer}>
							<Link to="/">Home</Link>
						</li>
						{/* {
                            HeaderData?.menu?.map((data: any, index: any) => {
                                return (
                                    <>
                                        <li key={index} onClick={closeDrawer}>
                                            <Link to={data.path} >
                                                {data.title}
                                            </Link>
                                        </li>
                                    </>
                                )
                            })
                        } */}
						{/* <li>
<HeaderButton/>
</li> */}
					</ul>

					<SocialIcons socialList={socialList} />
				</div>
			</Drawer>
		</>
	);
});
export default responsiveMenu;
