import { makeAutoObservable } from "mobx";
import axios from "axios";
import { userType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";

export default class AuthStore {
	// Variables
	public user?: userType;
	public token?: string;
	public app_loading: boolean;
	public email?: string;
	private rootStore: RootStore;
	public headerData?: any;
	public footerData?: any;

	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public InitializeApp = (): void => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.setNotificationConfig();
		this.setupHeaders();
	};

	public setupHeaders = (access_token?: string): void => {
		this.setAppLoading(true);

		this.rootStore
			.setAxiosHeaders(access_token)
			.then((token) => {
				this.setToken(token);
				// this.fetchAuthUser();
			})
			.catch(() => {
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};

	// Setter Functions
	public setUser = (value?: userType): void => {
		this.user = value;
	};
	public setToken = (value?: string): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};
	public setEmail = (value: string): void => {
		this.email = value;
	};

	public getHeaderData = async (): Promise<any> => {
		return await axios
			.post(API_URL.HEADER)
			.then(({ data }) => {
				this.headerData = data?.header;
				return this.headerData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getFooterData = async (): Promise<any> => {
		return await axios
			.post(API_URL.FOOTER)
			.then(({ data }) => {
				this.footerData = data?.footer;
				// console.log("this.footerData", this.footerData);
				return this.footerData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
