import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import FooterLogo from "../../assets/images/footerLogo.svg";
import { Link } from "react-router-dom";
import ScanImg from "../../assets/images/qrcode.png";
// import FormComponent from "./Components/FormComponent";

// import { ReactComponent as FaceBookIocn } from "../../assets/images/icons/facebook.svg";
// import { ReactComponent as InstagramIocn } from "../../assets/images/icons/instagram.svg";
// import { ReactComponent as TwitterIocn } from "../../assets/images/icons/twitter.svg";
// import { ReactComponent as IndeedIocn } from "../../assets/images/icons/indeed.svg";
import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";

//import FooterJson from "./footer.json";

const Footer: React.FC<any> = observer(() => {
	const {
		AUTH: { footerData, getFooterData },
		PRIVACY
	} = useStore();
	const offiecDetails = footerData?.office_address;
	const userLinks = footerData?.useful_link;
	const qrCode = footerData?.qr_section;
	const logoSection = footerData?.logo_section;
	const copySection = footerData?.footer_bottom;
	const socialList = footerData?.social_section;
	//const FooterData = FooterJson?.footer?.widgets
	useEffect(() => {
		getFooterData();
		PRIVACY.getPrivacyData()
	}, [getFooterData]);

	return footerData ? (
		<>
			<footer>
				<div className="container FooterContainer">
					<Row>
						<Col span={5}>
							<div className="leftFooterContent">
								<h4 className="footerHeading">
									{offiecDetails?.title !== "" ? (
										<span
											dangerouslySetInnerHTML={{
												__html: offiecDetails?.title,
											}}
											className="footerHeading"
										></span>
									) : (
										<span>Corporacte Office</span>
									)}
								</h4>
								{offiecDetails?.description !== "" ? (
									<div
										dangerouslySetInnerHTML={{
											__html: offiecDetails?.description,
										}}
										className="footerPera"
									></div>
								) : (
									<p className="footerPera">
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Has been the industrys.{" "}
									</p>
								)}
							</div>
							<div>
								<div className="socialIcons">
									<ul>
										{socialList?.map((data: any) => {
											return (
												<>
													<li key={data.id}>
														<a href={data.url} target="_blank" rel="noreferrer">
															<img
																src={data.logo}
																alt={data.name}
																className="icon"
															/>
														</a>
													</li>
												</>
											);
										})}
									</ul>
								</div>
							</div>
						</Col>
						<Col span={5}>
							<div className="usefulLinks">
								<h4 className="footerHeading">
									{userLinks?.title !== "" ? (
										<span>Useful Links</span>
									) : (
										<span
											dangerouslySetInnerHTML={{
												__html: userLinks?.title,
											}}
										></span>
									)}
								</h4>
								<div className="linkList">
									<Link
										to="/"
										onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
										}}
									>
										Home
									</Link>
									{/* {AUTH.footerData?.menu_section?.map(
										(data: any, index: any) => {
											return (
												<Link key={index} to={data?.url}>
													{data?.title}
												</Link>
												
											);
										}
									)} */}
								</div>
							</div>
						</Col>
						<Col span={4}>
							<div className="QrCode">
								<h4 className="footerHeading">
									{qrCode?.title !== "" ? (
										<span
											dangerouslySetInnerHTML={{
												__html: qrCode?.title,
											}}
										></span>
									) : (
										<span>QR Code for Website</span>
									)}
								</h4>
								{
									<img
										// src={qrCode?.image !== "" ? qrCode?.image : ScanImg}
										src={ScanImg}
										alt="qrCode Image"
									/>
								}
							</div>
						</Col>
						<Col span={10} className="beforeContent">
							<div className="rightFooterContent">
								<Link
									to="/"
									onClick={() => {
										window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
									}}
								>
									{
										<img
											src={
												logoSection?.image !== ""
													? logoSection?.image
													: FooterLogo
											}
											alt="footer Logo"
										/>
									}
								</Link>

								{logoSection?.title !== "" ? (
									// <div
									// 	className="rightFooterPera"
									// 	dangerouslySetInnerHTML={{
									// 		__html: logoSection?.title,
									// 	}}
									// ></div>
									<p className="rightFooterPera">
										We Are Tech Friendly.
										{/* Just easily book from your mobile. */}
									</p>
								) : (
									<p className="rightFooterPera">
										We Are Tech Friendly.
										{/* Just easily book from your mobile. */}
									</p>
								)}

								{/* <FormComponent /> */}
							</div>
						</Col>
					</Row>
				</div>
			</footer>
			<div className="bottomFooter">
				<Row className="container">
					<div className="bottomFooterLinks ">
						{copySection?.title !== "" ? (
							<p>{copySection?.title}</p>
						) : (
							<p>Copyright © 2023 Shree Nandan Courier Limited</p>
						)}

						<div className="rightSideLinks">
							{/* {copySection?.menu?.map(
								(data: any, index: any) => {
									return (
										<>
											<Link
												to={data?.btn_url}
												target="_blank"
												rel="noreferrer"
												key={index}
											>
												{data?.btn_text}
											</Link>
										</>
									);
								}
							)} */}
							<Link to={copySection?.btn_url} target="_blank" rel="noreferrer">
								{`${copySection?.btn_text}`}
							</Link>
							{/* <Link
								to={copySection?.btn_url2}
								target="_blank"
								rel="noreferrer"
							>
								{`${copySection?.btn_text2}`}
							</Link> */}
						</div>
					</div>
				</Row>
			</div>
		</>
	) : (
		<PageSpinner />
	);
});

export default Footer;
