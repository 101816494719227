import { observer } from "mobx-react";
import React from "react";
//import HeaderJson from "../header.json";
// import { ReactComponent as FaceBookIocn } from "../../../assets/images/icons/facebook.svg";
// import { ReactComponent as InstagramIocn } from "../../../assets/images/icons/instagram.svg";
// import { ReactComponent as TwitterIocn } from "../../../assets/images/icons/twitter.svg";
// import { ReactComponent as IndeedIocn } from "../../../assets/images/icons/indeed.svg";
// const socialLogo: any = {
// 	Facebook: <FaceBookIocn />,
// 	Instagram: <InstagramIocn />,
// 	Twitter: <TwitterIocn />,
// 	Indeed: <IndeedIocn />,
// };

const SocialIcons = observer((props: any) => {
	//const HeaderData = HeaderJson?.header;s
	const { socialList } = props;
	return (
		<>
			<div className="socialIcons" style={{ marginTop: "-2px" }}>
				<ul>
					{socialList?.map((data: any) => {
						return (
							<>
								<li key={data.id}>
									<a href={data.url} rel="noreferrer" target="_blank">
										<img src={data.logo} alt={data.name} className="icon" />
									</a>
								</li>
							</>
						);
					})}
				</ul>

				<div className="followPageLink">
					<span>Follow this page</span>
				</div>
			</div>
		</>
	);
});

export default SocialIcons;
