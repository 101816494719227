import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../../config/ApiUrl";

export default class TrackingStore {
	public trackData: any;
	public errorData: any;
	constructor() {
		makeAutoObservable(this);
	}
	public getTrackData = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.TRACKDATA, data)
			.then(({ data }) => {
				//console.log("data", data);
				this.trackData = data?.view;
				return this.trackData;
			})
			.catch(({ data }) => {
				this.errorData = data?.STATUS;
				return Promise.reject(data);
			});
	};
}
