import React from "react";
import { useRoutes } from "react-router-dom";

// import ContactUs from "../views/app_pages/ContactUs/Contact";
import AuthLayout from "../views/layouts/AuthLayout";

const ServiceGuide = React.lazy(()=>import("../views/app_pages/ServiceGuide"));
const TrackShipment =  React.lazy(()=> import(  "../views/app_pages/TrackShipment"));
const  PrivacyPolicy =  React.lazy(()=> import(  "../views/app_pages/PrivacyPolicy"));
const AboutUs = React.lazy(()=> import("../views/app_pages/AboutUs"));
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const Network = React.lazy(() => import("../views/app_pages/Network"));
const Contact = React.lazy(() => import("../views/app_pages/OurContact"));
const Home = React.lazy(() => import("../views/app_pages/Home"));


 
export default function Router() {
	const element = useRoutes(RouterConfig);
	return element;
}

export const RouterConfig = [ 
	{
		element: <AuthLayout />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
	
		
			{
				path: "/unauthorized",
				element: <Unauthorized />,
			},
			{
				path: "*",
				element: <PageNotFound />,
			},
	
			{
				path: "/about-us",
				element:<AboutUs/>,
			},
			{
				path: "/network",
				element:<Network/>,
			},
			{
				path: "/privacy-policy",
				element:<PrivacyPolicy/>,
			},
			{
				path: "/contact-us",
				element:<Contact/>,
			},
			{
				path: "/track-shipment/:id",
				element:<TrackShipment/>,
			},
			{
				path: "/service-guide",
				element:<ServiceGuide/>,
			},
		
			
		],
	},	
];
