const API_URL = {
	HEADER: "init/header",
	FOOTER: "init/footer",
	PAGES: {
		HOME: "pages/home",
		TRACKDATA:"tracking/web-view/consignment",
		ABOUT: "pages/about-us",
		CONTACT: "pages/contact-us",
		NETWORK: "pages/network",
		SERVICE: "pages/service-guid",
		PRIVACY: "pages/privacy-policy",
		LOCATION:"search/location-list",
		CENTERDETAIL:"search/location-detail",
	}

};

export default API_URL;
