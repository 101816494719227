import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";
import { converViewsAddress } from "../../../config/Global";
export default class SearchLocationStore {
	public locationData: any = null;
	public pincodeData: any = null;
	public centerData: any;
	public selected_id: any;


	constructor() {
		makeAutoObservable(this);
	}

	setCenterData = (data: any) => {
		if (data) {
			data.address = converViewsAddress(data);
		}
		this.centerData = data;
	};



	public getLocationData = async (payload: any): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.LOCATION, payload)
			.then(({ data }) => {
				// console.log("data ",data );

				this.locationData = data?.list?.data;

				return this.locationData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getPincodeData = async (payload: any): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.LOCATION, payload)
			.then(({ data }) => {
				// console.log("data ",data );

				this.locationData = data?.list?.data;

				return this.locationData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getCenterDetail = async (id: any): Promise<any> => {
		console.log("center", this.centerData);
		return await axios
			.post(`${API_URL.PAGES.CENTERDETAIL}/${id}`)
			.then(({ data }) => {
				this.setCenterData(data?.view);
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
