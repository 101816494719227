import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";

import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";
import HeaderMenuComponent from "../../components/Header/Components/HeaderMenuComponent";
import SocialIcons from "../../components/Header/Components/SocialIcons";
import ResponsiveMenu from "./Components/ResponsiveMenu";
import LogoComponent from "./Components/LogoComponent";

import { ReactComponent as MenuIcon } from "../../assets/images/icons/barIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

// import HeaderJson from "./header.json";


const HeaderView: React.FC = observer(() => {
	const { Header } = Layout;
	const { AUTH: { headerData, getHeaderData } } = useStore();
	const menuList = headerData?.menu_section
	const socialList = headerData?.social_section

	// const HeaderData = HeaderJson?.header
	const [click, setClick] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
   useEffect(() => {
		getHeaderData();
	}, [getHeaderData]);

	const handleClick = () => {
		setClick(!click);
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	const isSticky = () => {
		const header = document.querySelector(".main__page__header");
		const scrollTop = window.scrollY;
		scrollTop >= 100
			? header?.classList.add("isSticky")
			: header?.classList.remove("isSticky");
	};

	const close = () => {
		setIsOpen(false);
		setClick(false);
	};

	return headerData ? (
		<Header className="main__page__header">
			<div className="container headerContainer">
				<LogoComponent />
				<div style={{display:"flex"}}>
				{menuList ? <HeaderMenuComponent menuList={menuList} /> : null}
				{socialList ? <SocialIcons socialList={socialList}  /> : null}
				</div>
				
			</div>

			<div className="menuToggle" onClick={handleClick}>
				{!click ? <MenuIcon /> : <CloseIcon />}
			</div>

			<ResponsiveMenu closeDrawer={close} open={isOpen} />
		</Header>
	) : <PageSpinner />;
});

export default HeaderView;
