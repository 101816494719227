export const displayFormats = {
	DATE_FORMAT: 'MM-DD-YYYY',
	TIME_FORMAT: 'HH:mm',
	DATE_TIME_FORMAT: 'MM-DD-YYYY HH:mm',
};

//for use only without plural name Ex. cite's && state's && pincode's
export const converViewsAddress = (data:any) => {
	data.address = `${data.address1 ? `${data.address1} ,` : ''}${data.address2 ? `${data.address2} ,` : ''}${data?.address3 ? `${data.address3} ,` : ''}`
	data.address = data.address + ` \n ${data.states ? `${data.states.name} ,` : ''}${data.cities ? `${data.cities.name}` : ''}${data.pincode ? ` - ${data.pincode.pincode}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address
}